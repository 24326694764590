<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Mis Actividades</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text class="black--text">
	  		  	<v-row justify="space-between">
	  		  		<v-col cols="12" md="4" lg="2">
	  		  			<v-text-field
	  		  			  label="Fecha"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  type="date"
	  		  			  v-model="fecha"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="6" lg="4">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<!-- Estatus -->
	  		  	<v-row>
              <v-col cols="12" md="6" lg="2" class="align-center text-center">
                <v-card class="shadowCard pa-2">
                  <b>Actividades</b>    
                  <br/>
                  {{ actividades.length }}
                  <br/>
                  <br/>
                  <b>Realizadas</b>     
                  <br/>
                  {{ actividades.filter( el => el.cumplio == 1 ).length }}
                  <v-progress-linear
                    :value="(actividades.filter( el => el.cumplio == 1 ).length / actividades.length ) * 100 "
                    color="deep-purple accent-4"
                  ></v-progress-linear>
                  <br/>
                  <b>Sin realizar</b>   
                  <br/>
                  {{ actividades.filter( el => !el.cumplio ).length }}
                  <v-progress-linear
                    :value="(actividades.filter( el => !el.cumplio == 1 ).length / actividades.length ) * 100 "
                    color="primary"
                  ></v-progress-linear>
                  <br/>
                  <b>% Cumplimiento</b> 
                  <br/>
                  {{ actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 }}
                  <v-progress-linear
                    :value="actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 "
                    :color="colorCumplimiento"
                  ></v-progress-linear>

                  <span class="text-h3 mt-2">{{ emojisCalifica }}</span>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" class="black--text" align="center">
                <!-- KPI DE MARKETING -->
                <v-card color="shadowCard text-center" v-if="getdatosUsuario.puesto == 'Coord. Acad. Adultos'">
                  <b class="black--text text-h6 pt-6">{{ getdatosUsuario.puesto }}</b>
                  <br/>
                  <br/>
                  <b>Objetivo: {{ objetivo }} </b>
                  <br/>
                  <br/>
                  <b>KPI</b>
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
                        {{ totalFastM ? totalFastM.inscritos : '' }}
                      </v-card>
                      Fast
                    </div>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
                        {{ totalInbiM ? totalInbiM.inscritos : '' }}
                      </v-card>
                      Inbi
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    Valor aceptable: 
                  </v-card-text>

                  <v-card-text class="black--text text-h6 pt-0">
                    Comercial
                  </v-card-text>

                  <v-card-text class="black--text pt-0">
                    VENTAS NUEVAS TOTALES
                  </v-card-text>

                  <v-btn 
                    color="blue"
                    absolute
                    top
                    right
                    icon
                    @click="dialogComercial.estatus = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-card>

                <!-- KPI DE TI -->
                <v-card color="shadowCard text-center" v-if="getdatosUsuario.puesto == 'Jefe de TI'">
                  <b class="black--text text-h6 pt-6">{{ getdatosUsuario.puesto }}</b>
                  <br/>
                  <br/>
                  <b>Objetivo: {{ objetivo }} </b>
                  <br/>
                  <br/>
                  <b>KPI</b>
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card color="green darken-2" dark class="pa-4 text-center text-h3 shadowCard">
                        4.0
                      </v-card>
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    Valor aceptable: 3
                  </v-card-text>
                  
                  <v-card-text class="black--text text-h6 pt-0">
                    TI
                  </v-card-text>

                  <v-card-text class="black--text pt-0">
                    ÍNDICE DE SATISFACCIÓN DEL USUARIO
                  </v-card-text>

                  <v-btn 
                    color="blue"
                    absolute
                    top
                    right
                    icon
                    @click="dialogTI.estatus = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-card>

                <!-- FINANZAS -->
                <v-card color="shadowCard text-center" v-if="getdatosUsuario.puesto == 'Analista de Egresos'">
                  <b class="black--text text-h6 pt-6">{{ getdatosUsuario.puesto }}</b>
                  <br/>
                  <br/>
                  <b>Objetivo: {{ objetivo }} </b>
                  <br/>
                  <br/>
                  <b>KPI</b>
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card :color="finanzas.cumplio ? finanzas.cumplio >= 1 ? 'green' : 'red darken-2' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
                        {{ finanzas.cumplio ? finanzas.cumplio >= 1 ? 'SI' : 'NO' : 'NO' }}
                      </v-card>
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    Valor aceptable: 1
                  </v-card-text>
                  
                  <v-card-text class="black--text text-h6 pt-0">
                    Finanzas
                  </v-card-text>

                  <v-card-text class="black--text pt-0">
                    ÍNDICE DE EFICIENCIA FINANCIERA
                  </v-card-text>

                  <v-btn 
                    color="blue"
                    absolute
                    top
                    right
                    icon
                    @click="dialogFinanzas.estatus = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card class="shadowCard">
                  <v-data-table
                    :headers="headersReporte"
                    :items="reporte"
                    class="elevation-0"
                    :mobile-breakpoint="100"
                    dense
                    hide-default-footer
                  >
                  </v-data-table>
                </v-card>
              </v-col>

	  		  	</v-row>

            <v-row>
              <v-col cols="12" class="align-center text-center">
                <span class="text-h4 mt-2 semi-bold">{{ textoAvance }}</span>
                <v-progress-linear
                  :value="actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 "
                  :color="colorCumplimiento"
                  height="15"
                  rounded
                ></v-progress-linear>

              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="actividades"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

                  <template v-slot:item.hora_inicio="{ item }">
                    {{ item.hora_inicio }}
                    <br/>
                    a
                    <br/>
                    {{ item.hora_final }}
                  </template>

							    <template v-slot:item.estatus="{ item }">
							    	<v-chip v-if="item.estatus == 0" color="grey" small dark>Pendiente</v-chip>
							    	<v-chip v-if="item.estatus == 1" color="green" small dark>Terminada</v-chip>
							    	<v-chip v-if="item.estatus == 2" color="orange" small dark>En proceso</v-chip>
							    </template>

							    <template v-slot:item.cumplio="{ item }">
							    	<v-checkbox 
							    		v-model="item.cumplio" 
							    		:value="1"
							    		@change="updateActividad( item ) "
							    	></v-checkbox>
							    </template>
							  
							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idactividades_diarias }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="editedItem.fecha"
                label="Fecha"
                filled
                type="date"
                hide-details
                dense
                :readonly="editedIndex > -1 ? true :false "
              ></v-text-field>
            </v-col>

          	<v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="editedItem.hora_inicio"
                label="Hora inicio"
                filled
                type="time"
                hide-details
                dense
                :readonly="editedIndex > -1 ? true :false "
                min="08:00" max="21:00"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="editedItem.hora_final"
                label="Hora final"
                filled
                type="time"
                hide-details
                dense
                :readonly="editedIndex > -1 ? true :false "
                min="08:00" max="21:00"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                v-model="editedItem.actividad"
                label="Actividad"
                filled
                hide-details
                dense
                :rows="3"
                auto-grow
                :readonly="editedIndex > -1 ? true :false "
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="12">

              <v-autocomplete
                class="mt-2"
                filled
                dense
                clearable
                v-model="editedItem.objetivo"
                :items="clasificaciones"
                label="Selecciona Objetivo"
                item-text="clasificacion"
                item-value="idclasificacion_actividades"
                :readonly="editedIndex > -1 ? true :false "
                hide-details
              >
              </v-autocomplete>

            </v-col>
            <v-col cols="12">
            	<v-radio-group
					      v-model="editedItem.estatus"
					      row
					    >
					      <v-radio
					        label="Pendiente"
					        :value="0"
					      ></v-radio>
					      <v-radio
					        label="En proceso"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="Terminado"
					        :value="1"
					      ></v-radio>
					    </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      editedIndex: -1,

      editedItem: {
        idactividades_diarias:0,
				id_usuario:0,
				actividad:'',
				hora_inicio:'',
				hora_final:'',
				cumplio:0,
				objetivo:'',
				actividades:'',
				enfoque:'',
				kpi:'',
				fecha_creacion:null,
				fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				estatus:0,
      },

      defaultItem: {
        idactividades_diarias:0,
				id_usuario:'',
				actividad:'',
				hora_inicio:'',
				hora_final:'',
				cumplio:0,
				objetivo:'',
				actividades:'',
				enfoque:'',
				kpi:'',
				fecha_creacion:null,
				fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				estatus:0,
      },

      // tabla
			search:'',
      actividades: [],
      headers: [
        { text: 'H. Inicio'     , value: 'hora_inicio'      , align: 'center'},
        // { text: 'H. Final'      , value: 'hora_final'       },
        { text: 'Tiempo'        , value: 'tiempo'           },
        { text: 'Actividad'     , value: 'actividad'        },
        { text: '¿Cumplió?'     , value: 'cumplio'          },
        { text: 'Objetivo'      , value: 'objetivo_text'    },
        // { text: 'Enfoque'       , value: 'enfoque'          },
        // { text: 'Kpi'           , value: 'kpi'              },
        // { text: 'Estatus'       , value: 'estatus'          },
        { text: 'Actions'       , value: 'actions', sortable: false },
      ],

      headersReporte: [
        { text: 'Objetivo'     , value: 'objetivo'      },
        { text: 'Tiempo'       , value: 'tiempo_total'  },
        { text: 'Cumplió'      , value: 'tiempo_realizado'  },
        { text: '%'            , value: 'porcentaje'    },
      ],

      objetivo:'',
      clasificaciones:[],
      objetivo:'',
      clasificaciones:[],
      reporte:[],
      puesto:'',
      community: null,
      rh: null,
      finanzas: null,
      sistemas: null,
      academico: null,
      soporte: null,
      finanzasAuxi: null,
      rhAuxi: null,

      rh: { },
      totalFast: 0,
      totalInbi: 0,
      finanzas:{},
      totalFastM: 0,
      totalInbiM: 0,

      dialogRh:{
        estatus: false
      },
      dialogComercial:{
        estatus: false
      },
      dialogFinanzas:{
        estatus: false
      },
      dialogTI:{
        estatus: false
      },
      rhAuxi:{},

      totalFastRI: 1.3,
      totalInbiRI: 1.8,

      dialogOperaciones:{
        estatus: false
      },
      fechaini: null,
      fechafin: null,
      cicloInf: null,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Actividad' : 'Editar Actividad'
      },
      
      colorCumplimiento( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return 'red'
        }else if( cumplimiento <= 90 ){
          return 'orange'
        }else{
          return 'green'
        }
      },


      emojisCalifica( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return '😰'
        }else if( cumplimiento <= 90 ){
          return '🫠'
        }else{
          return '🤩'
        }
      },


      textoAvance( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 0 ){
          return 'Aún no empiezas 😡'
        }else if( cumplimiento <= 33 ){
          return 'Mirada que juzga 😑'
        }else if( cumplimiento <= 50 ){
          return 'Aún te falta mucho 🫤'
        }else if( cumplimiento <= 75 ){
          return 'Animóooooo Bruce! Bruce! Bruce! 😁'
        }else if( cumplimiento <= 90 ){
          return 'Ya meritoooo 🙂'
        }else{
          return 'Lo lograste, eres un Campeón 🤩'
        }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },


    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.actividades = []

        const payload = {
        	id_usuario: this.getdatosUsuario.iderp,
        	fecha:      this.fecha,
          idpuesto:   this.getdatosUsuario.idpuesto
        }

        return this.$http.post('actividades.get', payload).then(response=>{
          this.actividades     = response.data.actividades
        	this.objetivo        = response.data.objetivo
          this.clasificaciones = response.data.clasificaciones
          this.reporte         = response.data.reporte
        	this.cargar          = false

          this.actividades     = response.data.actividades
          this.clasificaciones = response.data.clasificaciones
          this.reporte         = response.data.reporte
          this.rh              = response.data.rh
          this.finanzas        = response.data.finanzas
          this.sistemas        = response.data.sistemas
          this.academico       = response.data.academico
          this.soporte         = response.data.soporte
          this.community       = response.data.community
          this.finanzasAuxi    = response.data.finanzasAuxi
          this.rhAuxi          = response.data.rhAuxi
          this.fechaini        = response.data.fechaini
          this.fechafin        = response.data.fechafin
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.actividades.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.actividades.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('actividades.update/' + this.editedItem.idactividades_diarias, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(!this.editedItem.objetivo){
      		return this.validarErrorDirecto('Favor de llenar el objetivo')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('actividades.update/' + this.editedItem.idactividades_diarias, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('actividades.add', this.editedItem).then(response=>{
          	this.initialize()
            this.editedItem.hora_inicio = this.editedItem.hora_final
            this.editedItem.actividad   = ''
            this.editedItem.objetivo    = 0
	        	this.cargar = false
        		// this.close()
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      updateActividad( item ){
      	this.cargar = true

      	this.$http.put('actividades.update/' + item.idactividades_diarias, item).then(response=>{
        	this.validarSuccess( response.data.message )
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

    },
  }
</script>


